import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

function About() {
  return (
    <div className="about-section">
      <MDBContainer className="pt-4">
        <MDBRow>
          <MDBCol>
            Há 12 anos prestando serviços de Fisioterapia ambulatorial e
            domiciliar Ortopédica, neurológica, pré e pós cirúrgico,postural
            escolioses e hérnias. Microfisioterapia, RPG, Terapia Manual, Dry
            Needling, Fisioterapia Integrativa, Pilates, abdominal hipopressivo.
          </MDBCol>
          <MDBCol>
            <div>
              <img
                src="./images/clinica/fachada.jpg"
                className="img-fluid rounded mb-4"
                alt="fachada clinica nova postura"
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default About;
