import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

export default function Maps() {
  return (
    <div style={{ height: "600px" }}>
      <MDBRow>
        <MDBCol>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d923.0114840075972!2d-48.12706383041552!3d-22.276249698731494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c7799c747abe3d%3A0x578f64c53721a5bc!2sCl%C3%ADnica%20de%20Fisioterapia%20Nova%20Postura%20Pilates%20e%20Rpg%20-%20Microfisioterapia%2C%20Fisioterapia%20integrativa%20e%20Pilates%20em%20Brotas!5e0!3m2!1sen!2sbr!4v1706152113207!5m2!1sen!2sbr"
            style={{ border: 0 }}
            width="100%"
            height="600px"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
