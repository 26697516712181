import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import CardsService from "./cards";

function Services() {
  return (
    <MDBContainer className="pt-4">
      <MDBRow>
        <MDBCol size="6">
          <div className="text-center">
            <img
              src="./images/pacientes/pacientes.jpeg"
              alt="pacientes"
              className="custom-image-services"
            />
          </div>
        </MDBCol>
        <MDBCol size="6">
          <div>
            <h1>Especialidades</h1>
          </div>
          <div>
            <span>
              Equipe exclusiva de fisioterapeutas com foco na sua saúde &
              bem-estar
            </span>
          </div>
          <div>
            <MDBListGroup style={{ minWidthL: "22rem" }} light>
              <MDBListGroupItem>Fisioterapia</MDBListGroupItem>
              <MDBListGroupItem>Pilates</MDBListGroupItem>
              <MDBListGroupItem>RPG</MDBListGroupItem>
              <MDBListGroupItem>Acupuntura</MDBListGroupItem>
              <MDBListGroupItem>Atendimento Domiciliar</MDBListGroupItem>
            </MDBListGroup>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="pt-4">
        <CardsService />
      </MDBRow>
    </MDBContainer>
  );
}

export default Services;
