import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

function Team() {
  return (
    <div className="team-section pt-4">
      <MDBContainer className="">
        <hr />
        <MDBRow>
          <MDBCol>
            <div className="text-center">
              <h1>
                <span>{"//"} </span>Nossa Equipe
              </h1>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="pt-4 pb-4">
          <MDBCol className="text-center">
            <img
              src="./images/equipe/equipe.png"
              alt="equipe de profissionais"
              className="img-fluid rounded"
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default Team;
