import NavBar from "../components/navbar";
import Menu from "../components/navbar/menu";
import Banner from "../components/banner";
import About from "../components/about";
import Services from "../components/services";
import Team from "../components/team";
import Estrutura from "../components/estrutura";
import Maps from "../components/maps";
import Footer from "../components/footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
function App() {
  return (
    <>
      <header>
        <NavBar />
        <Menu />
        <Banner />
      </header>
      <About />
      <Services />
      <Team />
      <Estrutura />
      <Maps />
      <Footer />
      <FloatingWhatsApp
        phoneNumber="5514991155511"
        accountName="Clínica Nova Postura"
        statusMessage="Respondemos dentro de alguns minutos"
        chatMessage="Olá 🤝, como podemos te ajudar?"
        placeholder="Digite sua mensagem"
        avatar="/images/logo/logo-redondo.png"
      />
    </>
  );
}

export default App;
