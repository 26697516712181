import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

function Estrutura() {
  return (
    <div className="estrutura-section pt-4 pb-4">
      <MDBContainer className="">
        <MDBRow>
          <MDBCol className="p-4">
            <div className="justify-content-start">
              <h5>Nova Postura</h5>
              <h1>
                <span>{"//"} </span>Conheça nossa clínica
              </h1>
            </div>
            <div>
              <p>
                A Nova postura nasceu da necessidade de colocarmos em prática
                toda bagagem de conhecimento adquirido mantendo a integridade
                dos nossos valores pessoais com foco na saúde das pessoas.
              </p>
              <p>
                Temos uma base na nossa graduação e pós-graduação em
                universidades como a XXXXX, YYYY e ZZZZZ e mantemos uma equipe
                com os mesmos ideais. "
              </p>
            </div>
            <div>
              <div className="d-flex align-items-center justify-content-center">
                <i className="far fa-2x fa-hand-point-right p-4"></i>
                <span className="d-flex align-items-center">
                  Dra. Greice Trevisan, fisioterapeuta, pos graduada em -
                  Proprietária da Clínica Nova Postura
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <i className="far fa-2x fa-hand-point-right p-4"></i>
                <span className="d-flex align-items-center">
                  Dra. Greice Trevisan, fisioterapeuta, pos graduada em -
                  Proprietária da Clínica Nova Postura
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <i className="far fa-2x fa-hand-point-right p-4"></i>
                <span className="d-flex align-items-center">
                  Dra. Greice Trevisan, fisioterapeuta, pos graduada em -
                  Proprietária da Clínica Nova Postura
                </span>
              </div>
            </div>
          </MDBCol>
          <MDBCol className="text-center">
            <MDBRow>
              <MDBCol className="p-2">
                <img
                  src="./images/estrutura/clinica1.jpeg"
                  alt="foto interna da clinica"
                  className="img-fluid rounded"
                />
              </MDBCol>
              <MDBCol className="p-2">
                <img
                  src="./images/estrutura/clinica2.jpeg"
                  alt="foto interna da clinica"
                  className="img-fluid rounded"
                />
              </MDBCol>
              <MDBCol className="p-2">
                <img
                  src="./images/estrutura/clinica3.jpeg"
                  alt="foto interna da clinica"
                  className="img-fluid rounded"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="p-2">
                <img
                  src="./images/estrutura/clinica5.jpeg"
                  alt="foto interna da clinica"
                  className="img-fluid rounded"
                />
              </MDBCol>
              <MDBCol className="p-2">
                <img
                  src="./images/estrutura/clinica4.jpeg"
                  alt="foto interna da clinica"
                  className="img-fluid rounded"
                />
              </MDBCol>
              <MDBCol className="p-2">6</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="p-2">7</MDBCol>
              <MDBCol className="p-2">8</MDBCol>
              <MDBCol className="p-2">9</MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default Estrutura;
