import React from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarBrand,
} from "mdb-react-ui-kit";

export default function NavBar() {
  return (
    <MDBNavbar expand="lg" light bgColor="light">
      <MDBContainer fluid>
        <MDBNavbarBrand href="#">
          <img
            src="./images/logo/logo-oficial.png"
            height="80"
            alt=""
            loading="lazy"
          />
        </MDBNavbarBrand>
        <MDBNavbarNav fullWidth={false} className="mb-2 mb-lg-0">
          <MDBNavbarItem>
            <MDBNavbarLink>
              <div className="d-flex justify-content-start">
                <i className="fab fa-2x fa-whatsapp icon-whatsapp"></i>
              </div>
              <div>
                <span className="text-nav-whatsapp">Fale pelo Whatsapp</span>
              </div>
              <div>
                <span className="phone-nav-whatsapp">(14) 99115-5511</span>
              </div>
            </MDBNavbarLink>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBContainer>
    </MDBNavbar>
  );
}
