import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <MDBFooter className="text-center text-lg-start text-light p-2 custom-footer">
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5 border-bottom">
          <MDBRow>
            <MDBCol md="4" lg="4" xl="6" className="mb-4">
              <MDBRow>
                <MDBCol size="6">
                  <img
                    src="./images/logo/logo-oficial.png"
                    className="img-fluid"
                    alt="logo-fapema-header"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="6">
                  <div className="pt-2">
                    <span>
                      Há 13 anos oferecendo tratamentos completos em Fisioterpia
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="6">
                  <section className="justify-content-center justify-content-lg-between">
                    <div className="pt-2">
                      <a href="" className="me-4 text-reset">
                        <MDBIcon
                          className="social-media-icons"
                          fab
                          icon="instagram"
                        />
                      </a>
                      <a href="" className="me-4 text-reset">
                        <MDBIcon
                          className="social-media-icons"
                          fab
                          icon="facebook"
                        />
                      </a>
                      <a href="" className="me-4 text-reset">
                        <MDBIcon
                          className="social-media-icons"
                          fab
                          icon="fab fa-whatsapp"
                        />
                      </a>
                    </div>
                  </section>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol md="4" lg="2" xl="3" className="mb-4">
              <h6 className="text-uppercase fw-bold mb-2 title-section-footer">
                Sobre
              </h6>
              <div className="pt-2">
                <a href="#!" className="text-reset">
                  A Clínica
                </a>
              </div>
              <div className="pt-2">
                <a href="#!" className="text-reset">
                  Fisioterpia
                </a>
              </div>
              <div className="pt-2">
                <a href="#!" className="text-reset">
                  Pilates
                </a>
              </div>
              <div className="pt-2">
                <a href="#!" className="text-reset">
                  Acunpuntura
                </a>
              </div>
              <div className="pt-2">
                <a href="#!" className="text-reset">
                  Atendimento Domiciliar
                </a>
              </div>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-2 title-section-footer">
                Contato
              </h6>
              <div className="pt-2">
                <MDBIcon icon="home" className="me-2 social-media-icons" />
                Av. Mario Pinoti, 1755
              </div>
              <div className="pt-2">São João, Brotas - SP</div>
              <div className="pt-2">
                <MDBIcon icon="envelope" className="me-3 social-media-icons" />
                contato@https://novaposturafisioterapia.com.br/
              </div>
              <div className="pt-2">
                <MDBIcon icon="phone" className="me-3 social-media-icons" /> +55
                (14) 99115-5511
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", fontSize: "13px" }}
      >
        Copyright © 2016.
        <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
          Todos os direitos reservados. Clínica Nova Postura Fisioterapia
        </a>
      </div>
    </MDBFooter>
  );
}
