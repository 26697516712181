import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function CarrouselCustom({ children }) {
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2500}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div>
        <img src="./images/banner/1.png" className="img-fluid"></img>
      </div>
      <div>
        <img src="./images/banner/2.png" className="img-fluid"></img>
      </div>
      <div>
        <img src="./images/banner/3.png" className="img-fluid"></img>
      </div>
      <div>
        <img src="./images/banner/4.png" className="img-fluid"></img>
      </div>
      <div>
        <img src="./images/banner/5.png" className="img-fluid"></img>
      </div>
    </Carousel>
  );
}

export default CarrouselCustom;
